<template>
  <div>
    <BlockUI :blocked="blocked" :fullScreen="true">
      <div class="flex flex-column gap-3 w-full mb-1">
        <div class="flex align-content-start" v-if="toolbar">
          <!-- Status -->
          <KBaseDropdown
            v-model="selectedStatusId"
            :options="optionStatuses"
            @change="loadNiches"
          />

          <!-- Category -->
          <KBaseDropdown
            v-model="selectedCategoryId"
            :options="optionCategories"
            @change="loadNiches"
          />

          <!-- Brands -->
          <KBaseDropdown
            v-model="selectedBrandId"
            :options="optionBrands"
            @change="loadNiches"
          />

          <!-- Products -->
          <KBaseDropdown
            v-model="selectedProductId"
            :options="optionProducts"
            @change="loadNiches"
          />
        </div>
        <div class="flex align-content-start gap-3">
          <InputText v-model="searchName" type="search" placeholder="Name" />
          <InputText v-model="searchNote" type="search" placeholder="Note" />
          <Button
            class="p-button-rounded"
            type="button"
            label="Search"
            @click="loadNiches"
          />
        </div>
      </div>
      <DataTable
        :value="dataSource"
        v-model:expandedRows="expandedRows"
        dataKey="id"
        :reorderableColumns="true"
        @columnReorder="onColReorder"
        @rowExpand="onRowExpand"
        @rowCollapse="onRowCollapse"
        responsiveLayout="scroll"
        :lazy="true"
        paginatorPosition="both"
        :paginator="true"
        :rows="10"
        :total-records="totalRecords"
        @page="onPage"
        @sort="onSort"
      >
        <template #empty>
          <div class="text-center">No niche found.</div>
        </template>
        <Column
          :expander="true"
          :reorderableColumn="false"
          headerStyle="width: 3rem"
          columnKey="expander"
        />
        <Column
          v-for="col of columns"
          :field="col.field"
          :header="col.header"
          :key="col.field"
          :style="col.style"
          sortable
          :reorderableColumn="true"
        >
          <template #body="{ field, data }">
            <template v-if="field == 'name'">
              <div class="flex align-items-center">
                <div class="w-10rem">
                  <router-link
                    :to="{
                      name: 'NicheAnalysisDetail',
                      params: { id: data.id },
                    }"
                  >
                    <div
                      v-tooltip.top="data.name"
                      class="w-full white-space-nowrap overflow-hidden text-overflow-ellipsis"
                    >
                      {{ data.name }}
                    </div>
                  </router-link>
                </div>
              </div>
              <div class="flex justify-content-center">
                <span class="text-xs font-bold">
                  {{ formatRegionToShortName(data.region) }}
                </span>
              </div>
            </template>
            <template v-else-if="field == 'status_id'">
              <KBaseDropdown
                v-model="data[field]"
                :options="rowOptionStatuses"
                @change="updateNicheStatus($event, data['id'])"
              />
            </template>
            <template v-else>
              {{ colFormatter(field, data[field], data["region"]) }}
            </template>
          </template>
        </Column>
        <Column
          :exportable="false"
          :reorderableColumn="false"
          style="min-width: 8rem"
          columnKey="calculator"
        >
          <template #header>
            <img src="@/assets/icons/Setting.png" alt="setting" />
          </template>
          <template #body="slotProps">
            <div class="flex flex-row align-items-center">
              <a
                :href="slotProps.data.sheet_url"
                target="_blank"
                rel="noreferrer noopener"
                class="mr-2"
              >
                <img
                  src="@/assets/icons/Table.png"
                  :alt="slotProps.data.name"
                />
              </a>
              <Button
                icon="pi pi-flag"
                class="p-button-rounded"
                type="button"
                @click="updateConversionShareJob(slotProps.data.id)"
                v-tooltip.top="'Click to update Conversion Share'"
                v-if="isFeChen()"
              />
            </div>
          </template>
        </Column>

        <template #expansion="slotProps">
          <ExpandedRow
            :id="slotProps.data.id"
            :categories="optionCategories"
            :brands="optionBrands"
            :products="optionProducts"
            :forceReload="forceReload"
            :region="slotProps.data.region"
            @change="handleChangeNiche"
            @forceReloadFunc="loadNiches"
          />
        </template>
      </DataTable>
    </BlockUI>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import store from "@/store";

import BlockUI from "primevue/blockui";
import Button from "primevue/button";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import { useToast } from "primevue/usetoast";

import ExpandedRow from "@/components/NicheAnalysis/ExpandedRow";
import KBaseDropdown from "@/components//KBaseDropDown";
import NicheFilterDropDownOptions from "@/components/NicheAnalysis/NicheFilterDropDownOptions";

import BrandService from "@/services/brand_service";
import NicheAnalysisService from "@/services/niche_analysis_service";
import ProductService from "@/services/product_service";

import {
  formatNumber,
  formatCurrency,
  formatPercent,
  formatUSDate,
  formatRegionToShortName,
} from "@/utils/formatter";

export default defineComponent({
  components: {
    BlockUI,
    Button,
    Column,
    DataTable,
    InputText,

    ExpandedRow,
    KBaseDropdown,
  },
  props: ["toolbar", "productId", "forceReload"],
  setup(props) {
    onMounted(() => {
      BrandService.list().then(({ data }) => {
        optionBrands.value = NicheFilterDropDownOptions.brands(
          data.brands.map((brand) => ({ label: brand.name, value: brand.id }))
        );
        selectedBrandId.value = optionBrands.value[0].value;
      });

      NicheAnalysisService.listStatuses().then(({ data }) => {
        rowOptionStatuses.value = data.statuses.map((status) => ({
          label: status.name,
          value: status.id,
        }));
        optionStatuses.value = NicheFilterDropDownOptions.statuses(
          rowOptionStatuses.value
        );
        selectedStatusId.value = optionStatuses.value[0].value;

        // row depend by status
        loadNiches();
      });

      NicheAnalysisService.listCategories().then(({ data }) => {
        optionCategories.value = NicheFilterDropDownOptions.categories(
          data.categories.map((category) => ({
            label: category.name,
            value: category.id,
          }))
        );
        selectedCategoryId.value = optionCategories.value[0].value;
      });

      ProductService.listProducts().then(({ data }) => {
        optionProducts.value = NicheFilterDropDownOptions.products(
          data.products.map((product) => ({
            label: product.name,
            value: product.id,
          }))
        );
        selectedProductId.value = optionProducts.value[0].value;
      });
    });

    const loadNiches = () => {
      blocked.value = true;

      let status_id = selectedStatusId.value;
      let category_id = selectedCategoryId.value;
      let brand_id = selectedBrandId.value;
      let product_id = selectedProductId.value;

      // niches of the product
      if (props.productId) {
        product_id = props.productId;
      }

      let query = { page: currentPage.value + 1 };
      if (status_id > 0) query["q[status_id_eq]"] = status_id;
      if (category_id > 0) query["q[category_id_eq]"] = category_id;
      if (brand_id > 0) query["q[brand_id_eq]"] = brand_id;
      if (product_id > 0) query["q[product_id_eq]"] = product_id;
      if (searchName.value.length > 0) query["q[name_cont]"] = searchName.value;
      if (searchNote.value.length > 0) query["q[note_cont]"] = searchNote.value;

      if (sortConfig.value.length > 0) {
        query["q[s]"] = sortConfig.value;
      }

      NicheAnalysisService.list(query)
        .then(({ data }) => {
          dataSource.value = data.niches;
          totalRecords.value = data.total;
        })
        .finally(() => (blocked.value = false));
    };

    const blocked = ref(false);
    const toast = useToast();

    const selectedStatusId = ref();
    const selectedCategoryId = ref();
    const selectedBrandId = ref();
    const selectedProductId = ref();
    const searchName = ref("");
    const searchNote = ref("");

    const optionBrands = ref(NicheFilterDropDownOptions.brands());
    const optionStatuses = ref(NicheFilterDropDownOptions.statuses());
    const optionCategories = ref(NicheFilterDropDownOptions.categories());
    const optionProducts = ref(NicheFilterDropDownOptions.products());

    const rowOptionStatuses = ref();

    const dataSource = ref();
    const expandedRows = ref([]);

    const totalRecords = ref(0);
    const currentPage = ref(0);
    const sortConfig = ref("");

    const columns = ref([
      {
        field: "score",
        header: "Score",
        formatter: formatNumber,
      },
      {
        field: "landing_cost",
        header: "Landing Cost",
        formatter: formatCurrency,
      },
      {
        field: "status_id",
        header: "Status",
      },
      {
        field: "name",
        header: "Name",
        style: "min-width: 14rem",
      },
      {
        field: "note",
        header: "Note",
        style: "min-width: 14rem",
      },
      {
        field: "avg_margin",
        header: "Avg. Margin",
        formatter: formatPercent,
      },
      {
        field: "avg_roi",
        header: "Avg. ROI",
        formatter: formatPercent,
      },
      {
        field: "avg_revenue",
        header: "Avg. Revenue",
        formatter: formatCurrency,
      },
      {
        field: "avg_sales",
        header: "Avg. Sales",
        formatter: formatNumber,
      },
      {
        field: "avg_price",
        header: "Avg. Price",
        formatter: formatCurrency,
      },
      {
        field: "avg_reviews",
        header: "Avg. Reviews",
        formatter: formatNumber,
      },
      {
        field: "ranked_keyword",
        header: "Ranked Keyword",
        formatter: formatNumber,
      },
      {
        field: "ranked_sv",
        header: "Ranked SV",
        formatter: formatNumber,
      },
      {
        field: "created_at",
        header: "Date",
        formatter: formatUSDate,
      },
    ]);

    const onColReorder = () => {};
    const onRowExpand = () => {};
    const onRowCollapse = () => {};

    const colFormatter = (field, value, region) => {
      const col = columns.value.filter((col) => col.field == field)[0];
      return col?.formatter ? col.formatter(value, region) : value;
    };

    const handleChangeNiche = (summary) => {
      dataSource.value.forEach((element) => {
        if (element.id === summary.id) {
          element.score = summary.score;
          element.landing_cost = summary.landing_cost;
        }
      });
    };

    const updateNicheStatus = (event, id) => {
      let payload = {
        status_id: event.value,
      };

      NicheAnalysisService.update(id, payload)
        .then(() => {
          loadNiches();
        })
        .catch((e) => {
          console.error(e);
        });
    };

    const updateConversionShareJob = (id) => {
      NicheAnalysisService.updateConversionShareJob(id)
        .then(() => {
          toast.add({
            severity: "success",
            summary:
              "Updating conversion shares, google sheet will be updated later",
            life: 3000,
          });
        })
        .catch((e) => {
          console.error(e);
          toast.add({
            severity: "error",
            summary: "Can not update conversion shares",
            life: 3000,
          });
        });
    };

    const isFeChen = () => {
      return store.getters.teamUuid === process.env.VUE_APP_FECHEN_UUID;
    };

    const onPage = (event) => {
      currentPage.value = event.page;
      loadNiches();
    };

    const onSort = (event) => {
      sortConfig.value = "";
      if (event.sortField) {
        let order = event.sortOrder == 1 ? "asc" : "desc";
        sortConfig.value = `${event.sortField} ${order}`;
      }
      loadNiches();
    };

    return {
      blocked,
      loadNiches,

      selectedStatusId,
      selectedCategoryId,
      selectedBrandId,
      selectedProductId,
      searchName,
      searchNote,

      optionStatuses,
      optionBrands,
      optionCategories,
      optionProducts,

      rowOptionStatuses,

      dataSource,
      expandedRows,
      columns,
      colFormatter,
      onColReorder,
      onRowExpand,
      onRowCollapse,

      formatNumber,
      formatCurrency,
      formatPercent,
      formatRegionToShortName,

      handleChangeNiche,
      updateNicheStatus,

      updateConversionShareJob,
      isFeChen,

      totalRecords,
      onPage,
      onSort,
    };
  },
});
</script>

<style lang="scss" scoped>
::v-deep tr.p-datatable-row-expansion {
  background-color: #f8f9fa;
}
</style>
