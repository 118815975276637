<template>
  <div class="expanded-row">
    <template v-if="isVisible">
      <div class="expanded-row-header">
        <div class="expanded-row-header-start">
          <div class="expanded-row-header-start-row">
            <div>
              <ScoreChip :label="summary.score" />
            </div>
            <div class="expanded-row-header-title">
              {{ summary.name }}
            </div>
          </div>
          <div class="expanded-row-header-start-row">
            <div>
              <!-- Category -->
              <KBaseDropdown
                v-model="selectedCategoryId"
                :options="categories"
                @change="updateCategory"
              />
            </div>
            <div>
              <!-- Brands -->
              <KBaseDropdown
                v-model="selectedBrandId"
                :options="brands"
                @change="updateBrand"
              />
            </div>
            <div>
              <!-- Products -->
              <KBaseDropdown
                v-model="selectedProductId"
                :options="products"
                @change="updateProduct"
              />
            </div>
          </div>
        </div>
        <div class="expanded-row-header-end">
          <div>
            <a
              :href="searchOnAmzUrl(region, summary.name)"
              target="_blank"
              rel="noreferrer noopener"
            >
              Search on Amazon
            </a>
          </div>
          <div>
            <a href="#">
              <img src="@/assets/icons/Calculator.png" alt="" />
            </a>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div class="expanded-row-subheader">
          <div>
            <span class="expanded-row-subheader-title">Note:</span>
            <span v-tooltip.bottom="summary.note" style="margin-left: 0.5rem">
              {{ formatShortString(summary.note) }}
            </span>
            <a
              href="#"
              @click="openEditNote(summary)"
              style="margin-left: 0.5rem"
            >
              Edit
            </a>
            <Dialog
              header="Edit Note"
              v-model:visible="displayEditNote"
              :style="{ width: '50vw' }"
              :modal="true"
            >
              <Textarea
                v-model="draftNote"
                rows="5"
                cols="30"
                :style="{ width: '45vw' }"
              />
              <template #footer>
                <Button
                  label="Cancel"
                  icon="pi pi-times"
                  @click="closeEditNote"
                  class="p-button-text"
                />
                <Button
                  label="Save"
                  icon="pi pi-check"
                  @click="onSaveNote(summary)"
                  autofocus
                />
              </template>
            </Dialog>
          </div>
        </div>
        <div class="expanded-row-subheader">
          <div>
            <span class="expanded-row-subheader-title">Target ASIN:</span>
            <MultiSelect
              v-model="summary.selected_listing_asins"
              :options="summary.listing_asins"
              :showToggleAll="false"
              :selectionLimit="1"
              placeholder="Select Target ASINs"
              display="chip"
              @change="onChangedTargetAsins"
              style="margin-left: 0.5rem"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <ProgressSpinner />
    </template>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";

import Button from "primevue/button";
import Dialog from "primevue/dialog";
import MultiSelect from "primevue/multiselect";
import ProgressSpinner from "primevue/progressspinner";
import Textarea from "primevue/textarea";

import KBaseDropdown from "@/components//KBaseDropDown";
import ScoreChip from "@/components/NicheAnalysis/ScoreChip";
import NicheFilterDropDownOptions from "@/components/NicheAnalysis/NicheFilterDropDownOptions";

import NicheAnalysisService from "@/services/niche_analysis_service";
import {
  formatNumber,
  formatCurrency,
  formatPercent,
  formatShortString,
  formatAmazonUrlByRegion,
} from "@/utils/formatter";

export default defineComponent({
  components: {
    Button,
    Dialog,
    MultiSelect,
    ProgressSpinner,
    Textarea,

    KBaseDropdown,
    ScoreChip,
  },

  props: ["id", "categories", "brands", "products", "forceReload", "region"],
  emits: ["change", "forceReloadFunc"],
  setup(props, { emit }) {
    onMounted(() => {
      loadNicheSummary();
    });

    const loadNicheSummary = () => {
      NicheAnalysisService.summary(props.id).then(({ data }) => {
        summary.value = data;
        // format
        selectedAmazonKwTrendScore.value = parseInt(
          summary.value.opportunity.amazon_kw_trend_score
        );
        selectedGoogleKwTrendScore.value = parseInt(
          summary.value.opportunity.google_kw_trend_score
        );

        summary.value.manual.price_volatility_percent =
          summary.value.manual.price_volatility * 100;

        summary.value.manual.easiness_score = parseFloat(
          summary.value.manual.easiness_score
        );
        summary.value.manual.price_high = parseFloat(
          summary.value.manual.price_high
        );
        summary.value.manual.price_low = parseFloat(
          summary.value.manual.price_low
        );

        selectedCategoryId.value = summary.value.category_id;
        selectedBrandId.value = summary.value.brand_id;
        selectedProductId.value = summary.value.product_id;

        isVisible.value = true;
        emit("change", data);
      });
    };

    const summary = ref();
    const isVisible = ref(false);

    const selectedAmazonKwTrendScore = ref();
    const selectedGoogleKwTrendScore = ref();

    const selectedTargetAsins = ref();

    const selectedCategoryId = ref();
    const selectedBrandId = ref();
    const selectedProductId = ref();

    // Target ASINs
    const onChangedTargetAsins = ({ value }) => {
      let payload = {
        target_asins: value,
      };

      updateNiche(payload);
    };

    // Note
    const draftNote = ref(false);
    const displayEditNote = ref(false);
    const openEditNote = (summary) => {
      draftNote.value = summary.note;
      displayEditNote.value = true;
    };

    const closeEditNote = () => {
      displayEditNote.value = false;
    };

    const onSaveNote = (summary) => {
      summary.note = draftNote.value;
      displayEditNote.value = false;

      let payload = {
        note: summary.note,
      };

      updateNiche(payload);
    };

    const searchOnAmzUrl = (region, keyword) => {
      const encodedKeyword = keyword.replaceAll(" ", "+");
      const amzUrl = formatAmazonUrlByRegion(region);
      return `${amzUrl}/s?k=${encodedKeyword}&ref=nb_sb_noss`;
    };

    const updateCategory = () => {
      let payload = {
        category_id: selectedCategoryId.value,
      };

      updateNiche(payload);
    };

    const updateBrand = () => {
      let payload = {
        brand_id: selectedBrandId.value,
      };

      updateNiche(payload);
    };

    const updateProduct = () => {
      let payload = {
        product_id: selectedProductId.value,
      };

      updateNiche(payload);
    };

    const updateNiche = (payload) => {
      NicheAnalysisService.update(props.id, payload)
        .then(() => {
          if (props.forceReload) {
            emit("forceReloadFunc");
          } else {
            loadNicheSummary();
          }
        })
        .catch((e) => {
          console.error(e);
        });
    };

    return {
      summary,
      isVisible,
      selectedAmazonKwTrendScore,
      selectedGoogleKwTrendScore,

      selectedTargetAsins,

      selectedCategoryId,
      selectedBrandId,
      selectedProductId,

      NicheFilterDropDownOptions,

      searchOnAmzUrl,

      formatNumber,
      formatCurrency,
      formatPercent,
      formatShortString,

      draftNote,
      displayEditNote,
      onChangedTargetAsins,
      openEditNote,
      closeEditNote,
      onSaveNote,

      updateCategory,
      updateBrand,
      updateProduct,
    };
  },
});
</script>

<style lang="scss" scoped>
.expanded-row {
  .expanded-row-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .expanded-row-header-start {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: flex-start;

      .expanded-row-header-start-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .expanded-row-header-end {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      div:not(:first-child) {
        margin-left: 1rem;
      }

      a {
        color: #3653f1;
      }
    }

    .expanded-row-header-title {
      font-weight: bold;

      margin-left: 1rem;
      margin-right: 1rem;
      padding-right: 1rem;
    }
  }

  .expanded-row-subheader {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    div {
      &::after {
        content: "|";
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }
      &:last-child::after {
        content: "";
        margin: 0;
      }

      .expanded-row-subheader-title {
        font-weight: bold;
      }
    }

    &:not(:first-child) {
      margin-top: 1rem;
    }
  }

  .expanded-row-content {
    display: flex;
    justify-content: flex-start;
    margin-top: 1rem;

    .expanded-row-content-block {
      display: flex;
      flex-direction: column;
      align-items: center;

      border-style: solid;
      border-width: 0 1px 0 0;
      margin-left: 1rem;
      margin-right: 1rem;
      padding-right: 1.5rem;

      &:last-child {
        border-width: 0;
      }

      .content-block-title {
        font-weight: bold;
        margin-bottom: 1rem;

        .score {
          margin-left: 0.3rem;
          color: #3653f1;
        }
      }

      .expanded-row-content-block-content {
        display: flex;
        justify-content: space-around;

        .column-title {
          font-weight: bold;
        }

        .column-middle-3rem {
          display: flex;
          align-items: center;
          height: 3rem;
        }

        .align-right-block {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          &:not(:first-child) {
            margin-left: 1.5rem;
          }

          .column {
            height: 2rem;
          }
        }

        .align-left-block {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          &:not(:first-child) {
            margin-left: 1.5rem;
          }

          .column {
            height: 2rem;
          }
        }

        .align-around-block {
          display: flex;
          justify-content: space-around;

          border-style: solid;
          border-width: 0 1px 0 0;
          margin-right: 1rem;
          padding-right: 1.5rem;

          &:last-child {
            border-width: 0;
            margin-right: 0;
            padding-right: 0;
          }
        }
      }
    }
  }
}
</style>
