const brands = [
  {
    label: "All Brands",
    value: 0,
  },
];

const statuses = [
  {
    label: "All Status",
    value: 0,
  },
];

const categories = [
  {
    label: "All Categories",
    value: 0,
  },
];

const products = [
  {
    label: "All Products",
    value: 0,
  },
];

class DropDownOptions {
  brands(options = []) {
    return brands.concat(options);
  }

  statuses(options = []) {
    return statuses.concat(options);
  }

  categories(options = []) {
    return categories.concat(options);
  }

  products(options = []) {
    return products.concat(options);
  }
}

export default new DropDownOptions();
