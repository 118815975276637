<template>
  <Chip
    :class="[light ? 'chip-score-light' : 'chip-score']"
    :label="formatNumber(label)"
  />
</template>

<script>
import { defineComponent } from "vue";

import Chip from "primevue/chip";
import { formatNumber } from "@/utils/formatter";

export default defineComponent({
  components: {
    Chip,
  },

  props: ["label", "light"],

  setup() {
    return {
      formatNumber,
    };
  },
});
</script>

<style scoped>
.chip-score {
  color: #ffffff;
  background-color: #3653f1;

  height: 1.5rem;
}

.chip-score-light {
  color: #3653f1;
  background-color: transparent;

  height: 1.5rem;

  border-style: solid;
  border-color: #3653f1;
  border-width: 1px;
}
</style>
