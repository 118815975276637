import { axiosApiInstance } from "@/services/base";

class NicheAnalysisService {
  list(params) {
    return axiosApiInstance.get(`/niche_analysis/niches`, { params: params });
  }

  summary(id) {
    return axiosApiInstance.get(`/niche_analysis/niches/${id}`);
  }

  update(id, payload) {
    return axiosApiInstance.put(`/niche_analysis/niches/${id}`, payload);
  }

  // Statues
  listStatuses() {
    return axiosApiInstance.get(`/niche_analysis/statuses`);
  }

  createStatus(payload) {
    return axiosApiInstance.post(`/niche_analysis/statuses`, payload);
  }

  deleteStatus(id) {
    return axiosApiInstance.delete(`/niche_analysis/statuses/${id}`);
  }

  resortStatus(payload) {
    return axiosApiInstance.put(`/niche_analysis/statuses/resort`, payload);
  }

  updateStatus(id, payload) {
    return axiosApiInstance.put(`/niche_analysis/statuses/${id}`, payload);
  }

  // Categories
  listCategories() {
    return axiosApiInstance.get(`/niche_analysis/categories`);
  }

  createCategory(payload) {
    return axiosApiInstance.post(`/niche_analysis/categories`, payload);
  }

  deleteCategory(id) {
    return axiosApiInstance.delete(`/niche_analysis/categories/${id}`);
  }

  resortCategory(payload) {
    return axiosApiInstance.put(`/niche_analysis/categories/resort`, payload);
  }

  updateCategory(id, payload) {
    return axiosApiInstance.put(`/niche_analysis/categories/${id}`, payload);
  }

  // Detail
  listKeywords(id, params) {
    return axiosApiInstance.get(`/niche_analysis/${id}/keywords`, {
      params: params,
    });
  }

  listKeywordsTrends(params) {
    return axiosApiInstance.get(`/niche_analysis/keywords_trends`, {
      params: params,
    });
  }

  listQuickFilters(id) {
    return axiosApiInstance.get(`/niche_analysis/${id}/quick_filters`);
  }

  saveQuickFilter(id, payload) {
    return axiosApiInstance.post(
      `/niche_analysis/${id}/quick_filters`,
      payload
    );
  }

  listRoots(id, params) {
    return axiosApiInstance.get(`/niche_analysis/${id}/roots`, {
      params: params,
    });
  }

  listListingVersions(id) {
    return axiosApiInstance.get(`/niche_analysis/${id}/listing_versions`);
  }

  updateConversionShareJob(id) {
    return axiosApiInstance.post(
      `/niche_analysis/${id}/update_conversion_share_jobs`
    );
  }
}

export default new NicheAnalysisService();
