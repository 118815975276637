<template>
  <div>
    <Card class="main-content">
      <template #content>
        <NicheTable :toolbar="true" />
      </template>
    </Card>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import Card from "primevue/card";

import NicheTable from "@/components/NicheAnalysis/NicheTable";

export default defineComponent({
  components: {
    Card,

    NicheTable,
  },

  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped>
.main-content {
  margin-top: 1rem;
}
</style>
